// src/app/error.tsx
"use client";

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <div className="min-h-[80dvh] flex items-center justify-center">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Algo deu errado!</h2>
        <p className="text-gray-600 mb-4">
          {process.env.NODE_ENV === "development"
            ? error.message
            : "Ocorreu um erro inesperado. Por favor, tente novamente."}
        </p>
        <button
  type="button"
  onClick={() => reset()}
  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-300"
  aria-label="Retry the action"
>
  Tentar novamente
</button>
      </div>
    </div>
  );
}
